
body {
  color: #797979;
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

h1,
h2, 
h3, 
h4,
h5, 
h6 {
  color: #414141;
}

a {
  color: #313131;
  transition: .3s;
}

a:hover,
a:active,
a:focus {
  color: #EF233C;
  outline: none;
  text-decoration: none;
}

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.container-fluid {
  max-width: 1366px;
}

.btn {
  padding: 12px 25px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #ffffff;
  background: #EF233C;
  border: 2px solid transparent;
  border-radius: 0;
  box-shadow: inset 0 0 0 50px #EF233C;
  transition: ease-out 0.3s;
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
}

.btn:hover {
  color: #EF233C;
  background: transparent;
  box-shadow: inset 0 0 0 0 #EF233C;
  border-color: #EF233C;
}

#loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s ease-out, visibility 0s linear .3s;
  -o-transition: opacity .3s ease-out, visibility 0s linear .3s;
  transition: opacity .3s ease-out, visibility 0s linear .3s;
  z-index: 999;
}

#loader.show {
  -webkit-transition: opacity .6s ease-out, visibility 0s linear 0s;
  -o-transition: opacity .6s ease-out, visibility 0s linear 0s;
  transition: opacity .6s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

#loader .loader {
  position: relative;
  width: 45px;
  height: 45px;
  border: 5px solid #dddddd;
  border-top: 5px solid #EF233C;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.back-to-top {
  position: fixed;
  width: 44px;
  height: 44px;
  padding: 8px 0;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  z-index: 9;
}

.back-to-top i {
  color: #ffffff;
}

.back-to-top:hover i {
  color: #414141;
}


.navbar {
  position: relative;
  transition: .5s;
  z-index: 999;
}

.navbar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

.navbar .navbar-brand {
  margin: 0;
  font-size: 45px;
  line-height: 0px;
  font-weight: 700;
  letter-spacing: 2px;
  transition: .5s;
  
}

.navbar .navbar-brand img {
  max-width: 100%;
  max-height: 40px;
}

.navbar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #f8f9fa;
}

@media (min-width: 992px) {
  .navbar {
      position: absolute;
      width: 100%;
      padding: 30px 60px;
      background: transparent !important;
      border-bottom: 1px dashed rgba(256, 256, 256, .2);
      z-index: 9;
  }
  
  .navbar.nav-sticky {
      padding: 10px 60px;
      background: #ffffff !important;
  }
  
  .navbar .navbar-brand {
      color: #ffffff;
  }
  
  .navbar.nav-sticky .navbar-brand {
      color: #EF233C;
  }

  .navbar-light .navbar-nav .nav-link,
  .navbar-light .navbar-nav .nav-link:focus {
      padding: 10px 10px 8px 10px;
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
  }
  
  .navbar-light.nav-sticky .navbar-nav .nav-link,
  .navbar-light.nav-sticky .navbar-nav .nav-link:focus {
      color: #414141;
  }

  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link.active {
      color: #111111;
  }
  
  .navbar-light.nav-sticky .navbar-nav .nav-link:hover,
  .navbar-light.nav-sticky .navbar-nav .nav-link.active {
      color: #EF233C;
  }
}

@media (max-width: 991.98px) {   
  .navbar {
      padding: 15px;
      background: #ffffff !important;
  }
  
  .navbar .navbar-brand {
      color: #EF233C;
  }
  
  .navbar .navbar-nav {
      margin-top: 15px;
  }
  
  .navbar a.nav-link {
      padding: 5px;
  }
  
  .navbar .dropdown-menu {
      box-shadow: none;
  }
}


.hero {
  position: relative;
  width: 100%;
  margin-bottom: 45px;
  padding: 120px 0 0 0;
  overflow: hidden;
  background: linear-gradient(rgba(239, 35, 60, .95), rgba(239, 35, 60, .95));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero .container-fluid {
  padding: 0;
}

.hero .hero-image {
  position: relative;
  text-align: right;
  padding-right: 75px;
}

.hero .hero-image img {
  max-width: 80%;
  max-height: 80%;
}

.hero .hero-content {
  position: relative;
  padding-left: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.hero .hero-text p {
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 15px;
}

.hero .hero-text h1 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 15px;
}

.hero .hero-text h2 {
  display: inline-block;
  margin: 0;
  height: 35px;
  color: #ffffff;
  font-size: 35px;
  font-weight: 600;
}

.hero .hero-text .typed-text {
  display: none;
}

.hero .hero-text .typed-cursor {
  font-size: 35px;
  font-weight: 300;
  color: #ffffff;
}

.hero .hero-btn .btn {
  margin-top: 35px;
  color: #EF233C;
  background: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
}

.hero .hero-btn .btn:hover {
  color: #ffffff;
  background: transparent;
  box-shadow: inset 0 0 0 0 #ffffff;
  border-color: #ffffff;
}

.hero .hero-btn .btn:first-child {
  margin-right: 10px;
}

@media (max-width: 991.98px) {
  .hero {
      padding-top: 60px;
  }
  
  .hero .hero-content {
      padding: 0 15px;
  }
  
  .hero .hero-text p {
      font-size: 20px;
  }
  
  .hero .hero-text h1 {
      font-size: 45px;
  }
  
  .hero .hero-text h2 {
      font-size: 25px;
      height: 25px;
  }
  
  .hero .hero-btn .btn {
      padding: 12px 30px;
      letter-spacing: 1px;
  }
}

@media (max-width: 767.98px) {
  .hero {
      padding-top: 60px;
      padding-bottom: 60px;
  }
  
  .hero,
  .hero .hero-text,
  .hero .hero-btn {
      width: 100%;
      text-align: center;
  }
  
  .hero .hero-text p {
      font-size: 18px;
  }
  
  .hero .hero-text h1 {
      font-size: 35px;
  }
  
  .hero .hero-text h2 {
      font-size: 22px;
      height: 22px;
  }
  
  .hero .hero-btn .btn {
      padding: 10px 15px;
      letter-spacing: 1px;
  }
}

@media (max-width: 575.98px) {
  .hero .hero-text p {
      font-size: 16px;
  }
  
  .hero .hero-text h1 {
      font-size: 30px;
  }
  
  .hero .hero-text h2 {
      font-size: 18px;
      height: 18px;
  }
  
  .hero .hero-btn .btn {
      padding: 8px 10px;
      letter-spacing: 0;
  }
}



.section-header {
  position: relative;
  margin-bottom: 45px;
}

.section-header p {
  display: inline-block;
  margin: 0 30px;
  margin-bottom: 10px;
  padding-left: 15px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #ffffff;
}

.section-header p::before {
  position: absolute;
  content: "";
  height: 3px;
  top: 11px;
  right: 0;
  left: -30px;
  background: #EF233C;
  z-index: -1;
}

.section-header p::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  top: 11px;
  left: 3px;
  background: #EF233C;
  z-index: 1;
}

.section-header h2 {
  margin: 0;
  position: relative;
  font-size: 45px;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .section-header h2 {
      font-size: 30px;
  }
}


.about {
  position: relative;
  width: 100%;
  margin: -45px 0 45px 0;
}

.about .col-lg-6 {
  padding: 0;
}

.about .section-header {
  margin-bottom: 30px;
}

.about .about-img {
  position: relative;
  height: 100%;
}

.about .about-img img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about .about-content {
  padding: 0 60px;
}

.about .about-text p {
  font-size: 16px;
}

.about .skills {
  margin-bottom: 30px;
}

.about .skill-name {
  margin-top: 15px;
}

.about .skill-name p {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
}

.about .skill-name p:last-child {
  float: right;
}

.about .progress {
  height: 10px;
  border-radius: 10px;
  background: #dddddd;
}

.about .progress .progress-bar {
  width: 0px;
  background: #EF233C;
  border-radius: 10px;
  transition: 2s;
}

.about .about-text a.btn {
  margin-top: 15px;
}

@media (max-width: 991.98px) {
  .about .about-content {
      padding: 45px 15px 0 15px;
  }
}


.experience {
  position: relative;
  padding: 45px 0 15px 0;
}

.experience .timeline {
  position: relative;
  width: 100%;
}

.experience .timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background: #EF233C;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.experience .timeline .timeline-item {
  position: relative;
  background: inherit;
  width: 50%;
  margin-bottom: 30px;
}

.experience .timeline .timeline-item.left {
  left: 0;
  padding-right: 30px;
}

.experience .timeline .timeline-item.right {
  left: 50%;
  padding-left: 30px;
}

.experience .timeline .timeline-item::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 48px;
  right: -8px;
  background: #ffffff;
  border: 2px solid #EF233C;
  border-radius: 16px;
  z-index: 1;
}

.experience .timeline .timeline-item.right::after {
  left: -8px;
}

.experience .timeline .timeline-item::before {
content: '';
position: absolute;
width: 0;
height: 0;
top: 46px;
right: 10px;
z-index: 1;
border: 10px solid;
border-color: transparent transparent transparent #dddddd;
}

.experience .timeline .timeline-item.right::before {
left: 10px;
border-color: transparent #dddddd transparent transparent;
}

.experience .timeline .timeline-date {
  position: absolute;
  width: 100%;
  top: 44px;
  font-size: 16px;
  font-weight: 600;
  color: #EF233C;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.experience .timeline .timeline-item.left .timeline-date {
  text-align: left;
  left: calc(100% + 55px);
}

.experience .timeline .timeline-item.right .timeline-date {
  text-align: right;
  right: calc(100% + 55px);
}

.experience .timeline .timeline-text {
  padding: 30px;
  background: #ffffff;
  position: relative;
  border-right: 5px solid #dddddd;
  box-shadow: 0 0 60px rgba(0, 0, 0, .08);
}

.experience .timeline .timeline-item.right .timeline-text {
  border-right: none;
  border-left: 5px solid #dddddd;
}

.experience .timeline .timeline-text h2 {
  margin: 0 0 5px 0;
  font-size: 22px;
  font-weight: 600;
}

.experience .timeline .timeline-text h4 {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}

.experience .timeline .timeline-text p {
  margin: 0;
  font-size: 16px;
}

@media (max-width: 767.98px) {
  .experience .timeline::after {
      left: 8px;
  }

  .experience .timeline .timeline-item {
      width: 100%;
      padding-left: 38px;
  }
  
  .experience .timeline .timeline-item.left {
      padding-right: 0;
  }
  
  .experience .timeline .timeline-item.right {
      left: 0%;
      padding-left: 38px;
  }

  .experience .timeline .timeline-item.left::after, 
  .experience .timeline .timeline-item.right::after {
      left: 0;
  }
  
  .experience .timeline .timeline-item.left::before,
  .experience .timeline .timeline-item.right::before {
      left: 18px;
      border-color: transparent #dddddd transparent transparent;
  }
  
  .experience .timeline .timeline-item.left .timeline-date,
  .experience .timeline .timeline-item.right .timeline-date {
      position: relative;
      top: 0;
      right: auto;
      left: 0;
      text-align: left;
      margin-bottom: 10px;
  }
  
  .experience .timeline .timeline-item.left .timeline-text,
  .experience .timeline .timeline-item.right .timeline-text {
      border-right: none;
      border-left: 5px solid #dddddd;
  }
}



.service {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.service .service-item {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 0 transparent;
  transition: ease-out 0.5s;
}

.service .service-item:hover {
  box-shadow: inset 800px 0 0 0 #EF233C;
}

.service .service-icon {
  position: relative;
  width: 150px;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EF233C;
  background: #ffffff;
}

.service .service-icon i {
  position: relative;
  font-size: 60px;
  color: #EF233C;
  transition: .3s;
}

.service .service-item:hover i {
  font-size: 75px;
}

.service .service-text {
  position: relative;
  width: calc(100% - 120px);
  padding: 0 30px;
}

.service .service-text h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  transition: 1s;
}

.service .service-text p {
  margin: 0;
  font-size: 16px;
  transition: 1s;
}

.service .service-item:hover .service-text h3,
.service .service-item:hover .service-text p {
  color: #ffffff;
}

@media (max-width: 575.98px) {
  .service .service-text h3 {
      font-size: 16px;
      margin-bottom: 5px;
  }
  
  .service .service-text p {
      font-size: 14px;
  }
}


.portfolio {
  position: relative;
  padding: 45px 0 15px 0;
}

.portfolio #portfolio-filter {
  padding: 0;
  margin: -15px 0 25px 0;
  list-style: none;
  font-size: 0;
  text-align: center;
}

.portfolio #portfolio-filter li {
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background: #EF233C;
  border: 2px solid transparent;
  border-radius: 0;
  box-shadow: inset 0 0 0 50px #EF233C;
  transition: ease-out 0.3s;
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
}

.portfolio #portfolio-filter li:hover,
.portfolio #portfolio-filter li.filter-active {
  color: #EF233C;
  background: transparent;
  box-shadow: inset 0 0 0 0 #EF233C;
  border-color: #EF233C;
}

.portfolio .portfolio-item {
  position: relative;
}

.portfolio .portfolio-wrap {
  position: relative;
  width: 100%;
}

.portfolio .portfolio-img {
  position: relative;
  overflow: hidden;
}

.portfolio .portfolio-img img {
  position: relative;
  width: 100%; 
  height: 100%; 
  object-fit: cover;
  transform: scale(1.1);
  transition: .5s;
}

.portfolio .portfolio-item:hover img {
  margin-left: 15px;
}

.portfolio .portfolio-text {
  position: relative;
  height: 60px;
  width: calc(100% - 30px);
  margin: -30px 15px 30px 15px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, .12);
}

.portfolio .portfolio-text h3 {
  width: calc(100% - 70px);
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 0 15px;
  white-space: nowrap;
  overflow: hidden;
}

.portfolio .portfolio-text a.btn {
  width: 50px;
  height: 50px;
  padding: 0 0 2px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  font-weight: 100;
}

.portfolio .portfolio-item:hover a.btn {
  color: #EF233C;
  background: transparent;
  box-shadow: inset 0 0 0 0 #EF233C;
  border-color: #EF233C;
}



.banner {
  position: relative;
  width: 100%;
  margin: 45px 0;
  padding: 90px 0;
  background: #EF233C;
}

.banner .container {
  max-width: 750px;
  text-align: center;
}

.banner .section-header {
  margin-bottom: 20px;
}

.banner .section-header p {
  color: #414141;
  background: transparent;
}

.banner .section-header p::after {
  display: none;
}

.banner .section-header h2 {
  color: #ffffff;
}

.banner .section-header h2 span {
  color: #414141;
  font-size: 50px;
}

.banner .banner-text p {
  font-size: 18px;
  color: #ffffff;
}

.banner .banner-text .btn {
  margin-top: 15px;
  color: #EF233C;
  background: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
}

.banner .banner-text .btn:hover {
  color: #ffffff;
  background: transparent;
  box-shadow: inset 0 0 0 0 #ffffff;
  border-color: #ffffff;
}



.price {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.price .row {
  padding: 0 15px;
}

.price .col-md-4 {
  padding: 0;
}

.price .price-item {
  position: relative;
  margin-bottom: 30px;
  background: #ffffff;
}

.price .featured-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, .2);
  z-index: 1;
}

.price .price-header,
.price .price-body,
.price .price-footer {
  position: relative;
  text-align: center;
}

.price .price-header {
  padding: 45px 0 30px 0;
  color: #414141;
}

.price .price-item.featured-item .price-header {
  color: #EF233C;
}

.price .price-title h2 {
  font-size: 25px;
  font-weight: 400;
  text-transform: uppercase;
}

.price .price-prices h2 {
  font-size: 45px;
  font-weight: 700;
  margin-left: 10px;
}

.price .price-prices h2 small {
  position: absolute;
  font-size: 18px;
  font-weight: 400;
  margin-top: 9px;
  margin-left: -12px;
}

.price .price-prices h2 span {
  margin-left: 1px;
  font-size: 18px;
  font-weight: 400;
}

.price .price-item.featured-item h2 {
  color: #EF233C;
}

.price .price-body {
  padding: 0 0 20px 0;
}

.price .price-description ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.price .price-description ul li {
  padding: 0 0 20px 0;
}

.price .price-item .price-action {
  padding-bottom: 45px;
}

.price .price-item .price-action .btn {
  color: #ffffff;
  background: #414141;
  box-shadow: inset 0 0 0 50px #414141;
}

.price .price-item .price-action .btn:hover {
  color: #414141;
  background: transparent;
  box-shadow: inset 0 0 0 0 #414141;
  border-color: #414141;
}

.price .price-item.featured-item .price-action .btn {
  color: #ffffff;
  background: #EF233C;
  box-shadow: inset 0 0 0 50px #EF233C;
}

.price .price-item.featured-item .price-action .btn:hover {
  color: #EF233C;
  background: transparent;
  box-shadow: inset 0 0 0 0 #EF233C;
  border-color: #EF233C;
}



.team {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.team .team-item {
  position: relative;
  background: #ffffff;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: inset 0 0 0 0 transparent;
  transition: ease-out 0.5s;
}

.team .team-img {
  position: relative;
  width: 50%;
  overflow: hidden;
}

.team .team-img img {
  position: relative;
  width: 100%;
  transition: .5s;
}

.team .team-text {
  position: relative;
  width: 50%;
  padding: 0 30px;
}

.team .team-text h2 {
  color: #EF233C;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  transition: 1s;
}

.team .team-text h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  transition: 1s;
}

.team .team-text p {
  margin-bottom: 20px;
  transition: 1s;
}

.team .team-social {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .team-social a.btn {
  width: 35px;
  height: 35px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  margin-right: 5px;
}

.team .team-item:hover {
  box-shadow: inset 800px 0 0 0 #EF233C;
}

.team .team-item:hover .team-img img {
  transform: scale(1.2);
}

.team .team-item:hover .team-text h2,
.team .team-item:hover .team-text h4,
.team .team-item:hover .team-text p {
  color: #ffffff;
}

.team .team-item:hover .team-social a.btn {
  background: #ffffff;
}

.team .team-item:hover .team-social a.btn:hover {
  border-color: #ffffff;
}

@media(max-width: 575.98px) {
  .team .team-text {
      padding: 0 15px;
  }
  
  .team .team-text h2 {
      font-size: 16px;
      margin-bottom: 0;
  }

  .team .team-text h4 {
      margin-bottom: 5px;
  }

  .team .team-text p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10px;
  }
}


.testimonial {
  position: relative;
  margin: 45px 0;
  padding: 90px 0;
  background: #EF233C;
}

.testimonial .container {
  max-width: 900px;
}

.testimonial .testimonial-icon {
  margin-bottom: 45px;
  text-align: center;
}

.testimonial .testimonial-icon i {
  font-size: 60px;
  color: rgba(256, 256, 256, .5);
}

.testimonial .testimonial-item {
  position: relative;
  margin: 0 15px;
  text-align: center;
}

.testimonial .testimonial-img {
  position: relative;
  margin-bottom: 15px;
  z-index: 1;
}

.testimonial .testimonial-item img {
  margin: 0 auto;
  width: 120px;
  padding: 10px;
  border: 5px dotted #ffffff;
  border-radius: 100px;
}

.testimonial .testimonial-text {
  position: relative;
  margin-top: -70px;
  padding: 65px 35px 30px 35px;
  text-align: center;
  background: #ffffff;
  border-radius: 500px;
}

.testimonial .testimonial-item p {
  font-size: 18px;
  font-style: italic;
}

.testimonial .testimonial-text h3 {
  color: #EF233C;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.testimonial .testimonial-text h4 {
  color: #666666;
  font-size: 14px;
  margin-bottom: 0;
}

.testimonial .owl-dots {
  margin-top: 15px;
  text-align: center;
}

.testimonial .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(256, 256, 256, .5);
}

.testimonial .owl-dot.active {
  background: #ffffff;
}


.contact {
  position: relative;
  width: 100%;
  margin: 45px 0;
  background: #EF233C;
}

.contact .container-fluid {
  /* background: url(../img/contact.jpg) left center no-repeat; */
  background-size: contain;
}

.contact .contact-form {
  position: relative;
  padding: 90px 0 90px 45px;
  background: #EF233C;
}

.contact .contact-form input {
  color: #ffffff;
  padding: 15px 0;
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(256, 256, 256, .5);
}

.contact .contact-form textarea {
  color: #ffffff;
  height: 90px;
  padding: 15px 0;
  background: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(256, 256, 256, .5);
}

.contact .contact-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.contact .contact-form .form-control:-ms-input-placeholder,
.contact .contact-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.contact .contact-form .btn {
  margin-top: 35px;
  color: #EF233C;
  background: #ffffff;
  box-shadow: inset 0 0 0 50px #ffffff;
}

.contact .contact-form .btn:hover {
  color: #ffffff;
  background: transparent;
  box-shadow: inset 0 0 0 0 #ffffff;
  border-color: #ffffff;
}

.contact .help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  font-style: italic;
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .contact .container-fluid {
      background: none;
  }
  
  .contact .contact-form {
      padding: 90px 0;
  }
}


.blog {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.blog .blog-item {
  position: relative;
  margin-bottom: 30px;
}

.blog .blog-img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.blog .blog-img img {
  width: 100%;
  transition: .5s;
}

.blog .blog-item:hover img {
  transform: scale(1.1);
}

.blog .blog-text {
  position: relative;
  padding: 30px;
  border-right: 1px solid rgba(0, 0, 0, .07);
  border-bottom: 1px solid rgba(0, 0, 0, .07);
  border-left: 1px solid rgba(0, 0, 0, .07);
}

.blog .blog-text h2 {
  font-size: 25px;
  font-weight: 600;
}

.blog .blog-text p {
  margin-bottom: 10px;
}

.blog .blog-item a.btn {
  margin-top: 10px;
  padding: 8px 15px;
}

.blog .blog-item a.btn i {
  margin-left: 5px;
}

.blog .blog-meta {
  position: relative;
  display: flex;
  margin-bottom: 15px;
}

.blog .blog-meta p {
  margin: 0 10px 0 0;
  font-size: 13px;
}

.blog .blog-meta i {
  color: #414141;
  margin-right: 5px;
}

.blog .blog-meta p:last-child {
  margin: 0;
}


.footer {
  position: relative;
  margin-top: 45px;
  background: #EF233C;
}

.footer .container-fluid {
  padding: 60px 0 0 0;
}

.footer .footer-info {
  position: relative;
  width: 100%;
  text-align: center;
}

.footer .footer-info h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
}

.footer .footer-info h3 {
  margin-bottom: 25px;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}

.footer .footer-menu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer .footer-menu p {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  padding: 0 15px;
  border-right: 1px solid #ffffff;
}

.footer .footer-menu p:last-child {
  border: none;
}

.footer .footer-social {
  position: relative;
  margin-top: 15px;
}

.footer .footer-social a {
  display: inline-block;
}

.footer .footer-social a i {
  margin-right: 15px;
  font-size: 20px;
  color: #ffffff;
  transition: .3s;
}

.footer .footer-social a:last-child i {
  margin: 0;
}

.footer .footer-social a:hover i {
  color: #414141;
}

.footer .copyright {
  position: relative;
  text-align: center;
  margin-top: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer .copyright::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  top: 0;
  left: 25%;
  background: rgba(256, 256, 256, .2);
}

.footer .copyright p {
  margin: 0;
  color: #ffffff;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #ffffff;
  font-weight: 600;
}

.footer .copyright p a:hover {
  color: #414141;
}

@media (max-width: 575.98px) {
  .footer .footer-info h2 {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
  }

  .footer .footer-info h3 {
      margin-bottom: 20px;
      font-size: 16px;
  }

  .footer .footer-menu p {
      font-size: 16px;
      line-height: 16px;
      padding: 0 5px;
  }
}
